import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Events from "./components/Events";

import MeasurementPointForm from "./components/MeasurementPointForm";

import GuardPage from "./components/GuardPage";
import Home from "./components/Home";

function App() {
  return (
    <BrowserRouter basename="/">
    <Routes>
    <Route path="/" element={ <div><MeasurementPointForm /> <Events /><GuardPage /></div>}/>
      <Route path="/rounds" element={<div><MeasurementPointForm /> <Events /></div> }/>
      <Route path="/guard-page" element={<GuardPage />} />
      <Route path="/events" element={<Events />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;

