import "./Events.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { firestore, firebase } from "../services/firebase";
import {
  GoogleMap,
  Marker,
  
} from "@react-google-maps/api";
import Home from "./Home";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "350px",
};
const center = {
  lat: -34.6775846,
  lng: -58.663793,
};
const defaultIcon = {
  url: "./point.png",
  scaledSize: new window.google.maps.Size(30, 30),
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(15, 15),
};
const selectedIcon = {
  url: "./avatar.png",
  scaledSize: new window.google.maps.Size(30, 30),
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(15, 15),
};

function MeasurementPointForm() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [measurementPoints, setMeasurementPoints] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [description, setDescription] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const mapRef = useRef();
  const [autocomplete, setAutocomplete] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
 
  
  useEffect(() => {
    const unsubscribe = firestore
      .collection("measurementPoints")
      .onSnapshot((querySnapshot) => {
        const points = [];
        querySnapshot.forEach((doc) => {
          points.push({ id: doc.id, ...doc.data() });
        });
        setMeasurementPoints(points);
      });
    return unsubscribe;
  }, []);
  useEffect(() => {
    if (!autocomplete) return;
  
    const listener = autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      setCurrentLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    });
  
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [autocomplete]);
  const handleMapClick = async (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const description = prompt('Nombre del punto:');
    const createdAt = new Date();
    const state = false;
  
    try {
      await firestore.collection('measurementPoints').add({
        location: {lat:lat, lng:lng},
        description,
        createdAt,
        state,
      });
    } catch (error) {
      console.error('Error adding measurement point: ', error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
  
    if (!selectedLocation ) {
      setError("Por favor, ingrese la ubicación y las fechas");
      setLoading(false);
      return;
    }
  
    try {
      await firestore.collection("measurementPoints").add({
        name: locationName,
        description: description,
        time: time,
        location: new firebase.firestore.GeoPoint(
          selectedLocation.lat,
          selectedLocation.lng
        ),
   
      });
      setLocationName("");
      setDescription("");
      setTime("");
      setSelectedLocation(null);
    
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenMap = useCallback(() => {
    const waypoints = measurementPoints
      .map((point) => `${point.location.lat},${point.location.lng}`)
      .join("|");
    const url = `https://www.google.com/maps/dir/?api=1&destination=${measurementPoints[measurementPoints.length - 1].location.lat},${measurementPoints[measurementPoints.length - 1].location.lng}&travelmode=driving&waypoints=${measurementPoints
      .slice(0, -1)
      .map((point) => `${point.location.lat},${point.location.lng}`)
      .join("|")}`;
    window.open(url, "_blank");
  }, [measurementPoints]);
  

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        setCurrentLocation(currentLocation);

        const map = mapRef.current;
        map.panTo(currentLocation);
        map.setZoom(18);
      });
    }
  };

  return (
   
    <div className="container-fluid">
    <Home>
     </Home><br></br>
     <GoogleMap
  mapContainerStyle={mapContainerStyle}
  zoom={12}
  center={currentLocation || center}
  onClick={handleMapClick}
  disableDefaultUI={true}
  onLoad={(map) => {
    mapRef.current = map;
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete-input"),
      { fields: ["geometry", "name"] }
    );
    setAutocomplete(autocomplete);
  }}
>
  {measurementPoints.map((point) => (
    <Marker
      key={point.id}
      position={{
        lat: parseFloat(point.location.lat),
        lng: parseFloat(point.location.lng),
      }}
      onClick={() => setSelectedLocation(point)}
    />
  ))}
  {selectedLocation && (
    <Marker
      position={{
        lat: parseFloat(selectedLocation.lat),
        lng: parseFloat(selectedLocation.lng),
      }}
      onCloseClick={() => setSelectedLocation}
    >
      <div>
        <h2>{selectedLocation.name}</h2>
        <p>{selectedLocation.description}</p>
      </div>
    </Marker>
  )}
  <button className="btn btn-primary" onClick={handleGetCurrentLocation}style={{ position: "relative", top: "5px", right: "5px", zIndex: "1" }}>
         mi ubicación 
      </button>
</GoogleMap><br></br>


<input id="autocomplete-input" type="text" placeholder="Buscar dirección" />  
  <div className="measurement-point-form"><br></br>
      <button onClick={handleOpenMap}>Ver Ronda </button></div>


    </div>  
  );
}

export default MeasurementPointForm;