import firebase from "firebase/app";
import "firebase/firestore";

// Configura tu app de Firebase aquí
const firebaseConfig = {
    apiKey: "AIzaSyDBMCpNzRnXMiLGVSh_973-HLPz_nDh1J8",
    authDomain: "sowaaguard.firebaseapp.com",
    projectId: "sowaaguard",
    storageBucket: "sowaaguard.appspot.com",
    messagingSenderId: "630974299362",
    appId: "1:630974299362:web:d5796a52b0464e668bb2bb"
};

// Inicializa Firebase
firebase.initializeApp(firebaseConfig);

// Exporta el objeto de Firestore
export const firestore = firebase.firestore();
export { firebase};