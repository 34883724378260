import React, { useEffect, useState } from "react";
import { firebase} from "../services/firebase";
import "./Events.css";
import Home from "./Home";

function Events() {
  const [ubicacionActual, setUbicacionActual] = useState(null);
  const [puntos, setPuntos] = useState([]);
  const [obtenerUbicacion, setObtenerUbicacion] = useState(false);
  const [estadoGeolocalizacion, setEstadoGeolocalizacion] = useState("inactivo");

  useEffect(() => {
    let unsubscribe;
    if (obtenerUbicacion) {
      unsubscribe = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUbicacionActual({ lat: latitude, lng: longitude });
          setObtenerUbicacion(false);
        },
        (error) => {
          console.log(error);
          setObtenerUbicacion(false);
        }
      );
    }
    return () => {
      if (unsubscribe) {
        navigator.geolocation.clearWatch(unsubscribe);
      }
    };
  }, [obtenerUbicacion]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("measurementPoints")
      .onSnapshot((snapshot) => {
     
        const nuevosPuntos = snapshot.docs.map((doc) => ({
          id: doc.id,
          state: doc.data().state,
         location: doc.data().location,
         points: doc.data().points,
          description: doc.data().description,
          vigilador: doc.data().location?.vigilador
        }));
        setPuntos(nuevosPuntos);
      });
    return unsubscribe;
  }, []);

  const completarPunto = (punto) => {
    firebase
      .firestore()
      .collection("measurementPoints")
      .doc(punto.id)
      .update({ state: true, points: new Date() })
      .then(() => {
        firebase
          .firestore()
          .collection("measurementPoints")
          .doc(punto.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setPuntos((prevPuntos) =>
                prevPuntos.map((p) =>
                  p.id === punto.id ? { ...p, description: doc.data().description } : p
                )
              );
            }
           
          })
          .catch((error) => {
            console.log("Error al obtener el nombre del punto:", error);
          });
      })
      .catch((error) => {
        console.log("Error al completar el punto:", error);
      });
  };

  const validarCoordenadas = (punto) => {
    const radio = 1000; // Cambiar el radio a 100 metros
    if (validarUbicacion(punto.location, ubicacionActual, radio)) {
      completarPunto(punto);
    } else {
      alert("Las coordenadas no están dentro del radio permitido");
    }
  };

  const validarUbicacion = (ubicacion1, ubicacion2, radio) => {
    if (!ubicacion1 || !ubicacion2) {
      return false;
    }
    const lat1 = ubicacion1?.lat;
    const lon1 = ubicacion1?.lng;
    const lat2 = ubicacion2?.lat;
    const lon2 = ubicacion2?.lng;

    const R = 6371 * 1000; // metros
    const φ1 = lat1 * (Math.PI / 180); // φ, λ en radianes
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // Distancia en metros
    return d <= radio;
  };
  const solicitarUbicacion = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUbicacionActual({
           lat: position.coords.latitude,
           lng: position.coords.longitude,
          });
          setEstadoGeolocalizacion("activo");
        },
        (error) => {
          console.log(error);
          alert("No se pudo obtener la ubicación");
        }
      );
    } else {
      alert("La geolocalización no está disponible en este navegador");
    }
  };



  
  return (
    
    <div className="container-fluid">
      <Home>
     </Home><br></br>
      <h1 className="text-center mb-4">LISTA DE EVENTOS</h1>
    
      <button className="btn btn-primary" onClick={() => solicitarUbicacion()}>
  {estadoGeolocalizacion === "inactivo" ? "Acceso a la ubicación" : "Ubicaion obtenida"}
</button><br></br><br></br>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
        <thead>
  <tr>
    
    <th>Nombre</th>
    <th>Estado</th>
    <th>Fecha Completado</th> 
    <th>Accion</th>
  </tr>
</thead>
<tbody>
  {puntos.map((punto) => (
    <tr key={punto.id}>
  
      <td>{punto.description}</td>
      
      <td>Completado: {punto.state ? "Sí" : "No"}</td>
      <td>
        {punto.state && punto.points ?
          new Date(punto.points.toDate()).toLocaleString() :
          "-"
        } 
      </td>
      <td>
        <button className="btn btn-primary" onClick={() => validarCoordenadas(punto)}>Completar</button>
      </td>
    </tr>
  ))}
</tbody>
          


</table>
</div>
</div>
      
      
    
  );

  
}

export default Events;