import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Home = () => {
 
    return (
        <Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand as={Link} to="/">Sowaapp Guards</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link as={Link} to="/">Home</Nav.Link>
        <Nav.Link as={Link} to="/rounds">Rondas</Nav.Link>
        <Nav.Link as={Link} to="/guard-page">Guardia</Nav.Link>
        <Nav.Link as={Link} to="/events">Eventos</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
      );
    }

export default Home;